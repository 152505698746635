<template>
    <el-dialog
            title="提示"
            :visible.sync="dialogVisible"
            width="420px"
            top="0"
            @open="openHandle"
            :close-on-click-modal="false"
    >
        <div class="info_2">
            订单已超时，请重新报名！
        </div>
        <span slot="footer" class="dialog-footer">
          <!-- 去注册区域 -->
          <el-button class="confirm" :disabled="time>0" @click="confirm()">
              <span class="time" v-show="time>0">{{time}}</span>
              确认
          </el-button>
        </span>
    </el-dialog>
</template>

<script>
    export default {
        name: "MessageBox",
        props: {
            messDialog: Boolean
        },
        data() {
            return {
                time: 3,
                timers: null,// 定时器
                dialogVisible: this.messDialog,
            };
        },
        methods: {
            openHandle() {
                this.timers = setInterval(() => {
                    this.time -= 1
                    if (this.time <= 0) {
                        clearInterval(this.timers)
                    }
                }, 1000)
            },
            // 返回登录页面
            confirm() {
                this.dialogVisible = false;
                this.$emit('closeMessage', false)
            },
        },
        watch: {
            messDialog() {
                this.dialogVisible = this.messDialog;
            },
        },
    };
</script>

<style scoped lang="scss">
    @import "../../../assets/scss/dialog";

    ::v-deep .el-dialog__body {
        padding: 0 48px 30px 48px !important;
    }

    ::v-deep .el-dialog__footer {
        margin: 0;
        padding: 10px 0 30px !important;
        text-align: center;
    }

    ::v-deep .el-icon-close:before {
        content: "";
    }

    .el-button {
        &.is-disabled {
            opacity: 0.5;
        }

        .time {
            font-family: PingFangSC-Regular;
        }
    }

</style>
