<template>
    <div id="container">
        <el-main>
            <!-- 面包屑导航区域 -->
            <bread-crumb
                    :type="2"
                    :step="6"
            ></bread-crumb>

            <!-- 主干区域 -->
            <div class="content">
                <div class="h_title">确认报名信息</div>
                <div class="h_info" v-if="examInfo.status == 2 && (examInfo.exam_type == 4 || examInfo.exam_type == 7)">
                    注：考试名额已锁定，等待用户支付，剩余<span> {{ remainTime }} </span
                >自动关闭。请尽快完成支付。
                </div>
                <div class="h_info" v-if="examInfo.status == 1">
                    注：<span style="font-size:16px;">订单已支付，正在跳转中...</span>
                </div>
                <div class="numner info_txt">
                    报名编号：{{ examInfo.order_no ? examInfo.order_no : "-" }}
                </div>
                <div class="info_txt">
                    姓名：{{ studentInfo.student_name ? studentInfo.student_name : "-" }}
                </div>
                <div class="info_txt">
                    证件号码：{{ studentInfo.id_card ? studentInfo.id_card : "-" }}
                </div>
                <div class="info_txt">
                    考试名称：{{ examInfo.exam_name ? examInfo.exam_name : "-" }}
                </div>
                <div class="info_txt">
                    考试时间：{{ examInfo.execute_time ? examInfo.execute_time : "-" }}
                </div>
                <div class="info_txt" v-if="examInfo.exam_type == 4 || examInfo.exam_type == 7">
                    考试科目：{{ examInfo.subject ? examInfo.subject : "-" }}
                </div>
                <div class="info_txt" v-if="examInfo.exam_type == 4">
                    预定考点：{{ examInfo.city ? examInfo.city : "-" }}
                </div>
                <div class="info_txt" v-if="examInfo.exam_type == 7">
                    预定考点：{{ examInfo.province ? examInfo.province : "-" }}
                </div>

                <!-- 价格 -->
                <div class="price">
                    <div v-if="examInfo.exam_type != 7">
                        报名费用：<span>￥{{ (examInfo.amount||examInfo.amount===0||examInfo.amount===0) ? examInfo.amount : "-" }}</span>
                    </div>

                </div>
                <!-- 底部按钮 -->
                <div class="foot-btn" v-if="examInfo.status == 2">
                    <el-button class="btn_1" v-if="examInfo.exam_type == 4 || examInfo.exam_type == 7" @click="cancelReserve()">取消预定</el-button>
                    <el-button class="btn_2" v-if="examInfo.amount==0" @click="confirmSignUp()">立即报名</el-button>
                    <el-button class="btn_2" v-if="examInfo.amount>0" @click="confirmSignUp()">支付费用</el-button>
                </div>
                <!-- 底部按钮 -->
                <div class="foot-btn" v-if="examInfo.status == 3">
                    <el-button class="btn_2" @click="$router.replace({ name: 'examlist' })"
                    >返回考试列表</el-button>
                </div>
            </div>

            <cancel-reserve
                    :examuuid="examuuid"
                    :reserveDialogFu="reserveDialogFu"
                    @closeReserveDialog="closeReserveDialog"
            ></cancel-reserve>

            <confirm-sign-up
                    :examuuid="examuuid"
                    :confirmSignUpDialogFu="confirmSignUpDialogFu"
                    @closeCFSUDialog="closeCFSUDialog"
                    @setOrderStatus="setOrderStatus"
            ></confirm-sign-up>

            <!--相关说明弹框-->
            <agree-box
                    :agreeBoxDialogFu="agreeBoxDialogFu"
                    @closeABDialog="closeABDialog"
            ></agree-box>
            <!--message弹框-->
            <message-box :messDialog="messDialog" @closeMessage="closeMessage"></message-box>
        </el-main>
    </div>
</template>

<script>
    import breadCrumb from "c/breadcrumb";
    import CancelReserve from "c/index/signUp/CancelReserve";
    import ConfirmSignUp from "c/index/signUp/ConfirmSignUp";
    import AgreeBox from "c/index/signUp/AgreeBox";
    import MessageBox from "c/index/signUp/MessageBox";
    import {getOrderInfo} from "r/index/order.js";
    import {djTime} from "s/time.js";
    import {SERVER_WS_URL} from "config/server";

    export default {
        name: "ConfirmInfo",
        data() {
            return {
                examInfo: {},
                studentInfo: {},
                remainTime: "", // 剩余时间
                timer: false, // 倒计时计时器
                reserveDialogFu: false, // 控制取消预定弹框的显示与隐藏
                orderno: "", // 订单号
                examuuid: "", // 考试id
                confirmSignUpDialogFu: false, // 控制确认报名弹框的显示与隐藏
                agreeBoxDialogFu: false, // 控制相关说明弹框的显示与隐藏

                token: "",
                websocket: null, //websocket连接
                lockReconnect: false, //是否真正建立连接
                timeout: 28 * 1000, //30秒一次心跳
                timeoutObj: null, //心跳心跳倒计时
                serverTimeoutObj: null, //心跳倒计时
                timeoutnum: null, //断开 重连倒计时
                order_status: false, //false-只预约然后超时，true-点击支付费用未支付预约超时
                messDialog: false,// 控制message弹框显示与隐藏
            };
        },
        created() {
            this.token = window.localStorage.getItem("index-token");
            if (
                !window.localStorage.getItem("exam_uuid") ||
                window.localStorage.getItem("exam_uuid") == "" ||
                window.localStorage.getItem("exam_uuid") == "null"
            ) {
                return this.$router.replace({name: "examlist"});
            }
            this.examuuid = window.localStorage.getItem("exam_uuid");
            this.orderno = window.localStorage.getItem("order_no");
            this.getOrderInfo();
        },
        methods: {
            // 子组件触发，关闭message弹框
            closeMessage(val) {
                this.messDialog = val;
                if (this.order_status) {
                    return this.$router.replace({name: "examlist"});
                }
                return this.$router.replace({name: "SignUpLists"});
            },
            // 子组件触发，重置订单状态
            setOrderStatus(val) {
                this.order_status = val;
            },
            // 子组件触发，关闭相关说明弹框
            closeABDialog(val, type) {
                this.agreeBoxDialogFu = val;
                if (type) {
                    this.confirmSignUpDialogFu = true;
                }
            },
            // 子组件触发，关闭确认报名弹框
            closeCFSUDialog(val, type) {
                this.confirmSignUpDialogFu = val;
                if (type) {
                    clearInterval(this.timer);
                }
            },
            // 子组件触发，关闭取消预定弹框
            closeReserveDialog(val, type) {
                this.reserveDialogFu = val;
                if (type) {
                    clearInterval(this.timer);
                    this.$router.replace({name: "SignUpLists"});
                }
            },
            // 确认报名
            confirmSignUp() {
                window.localStorage.setItem("exam_type", this.examInfo.exam_type);
                this.agreeBoxDialogFu = true;
            },
            // 取消预定
            cancelReserve() {
                console.log("dd");
                this.reserveDialogFu = true;
            },
            // 获取订单详情信息
            async getOrderInfo() {
                this.examuuid = window.localStorage.getItem("exam_uuid");
                this.orderno = window.localStorage.getItem("order_no");
                let {data: res} = await getOrderInfo({
                    exam_uuid: this.examuuid,
                    order_no: this.orderno,
                });
                console.log(res);
                if (res.code !== 200) return this.$message.error(res.message);
                this.examInfo = res.info;
                this.studentInfo = res.student;
                window.localStorage.setItem("exam_type", this.examInfo.exam_type);
                if (res.info.order_status == 5) {
                    this.order_status = true;
                }
                if (res.info.status == 2 && (res.info.exam_type == 4 || res.info.exam_type == 7)) {
                    this.remainTime = djTime(res.info.end_time);
                    this.timer = setInterval(() => {
                        if (this.examInfo.end_time > 0) {
                            this.examInfo.end_time -= 1;
                            return this.remainTime = djTime(this.examInfo.end_time);
                        }
                        clearInterval(this.timer);
                        this.messDialog = true;
                    }, 1000);
                } else if (res.info.status == 1) {
                    this.initWebSocket()
                }
            },
            // 初始化 webSocket
            initWebSocket() {
                if (typeof WebSocket == "undefined") {
                    this.$message({
                        showClose: true,
                        message: "您的浏览器不支持WebSocket",
                        type: "error",
                    });
                } else {
                    if (!this.token || this.token == "") {
                        this.token = window.localStorage.getItem("index-token");
                    }
                    const wssURL =
                        SERVER_WS_URL + "/city?token=" + this.token + "&channel=pc";
                    this.websocket = new WebSocket(wssURL);

                    // console.log(this.websocket)
                    // 连接发生错误的回调方法
                    this.websocket.onerror = this.websocketOnerror;
                    // 连接成功建立的回调方法
                    this.websocket.onopen = this.websocketOnopen;
                    // 接收到消息的回调方法
                    this.websocket.onmessage = this.websocketOnmessage;
                    // 连接关闭的回调方法
                    this.websocket.onclose = this.websocketOnclose;
                    // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常。
                    this.websocket.onbeforeunload = this.websocketOnbeforeunload;
                    // this.websocketSend();
                    // setTimeout(() => {
                    //   this.websocketSend();
                    // }, 1000);
                }
            },
            reconnect() {
                //重新连接
                let that = this;
                if (!that.dialogVisible) {
                    return;
                }
                if (that.lockReconnect) {
                    return;
                }
                that.lockReconnect = true;
                //没连接上会一直重连，设置延迟避免请求过多
                that.timeoutnum && clearTimeout(that.timeoutnum);
                that.timeoutnum = setTimeout(function () {
                    //新连接
                    that.initWebSocket();
                    that.lockReconnect = false;
                }, 5000);
            },
            reset() {
                //重置心跳
                let that = this;
                //清除时间
                clearTimeout(that.timeoutObj);
                clearTimeout(that.serverTimeoutObj);
                //重启心跳
                that.start();
            },
            start() {
                //开启心跳
                let self = this;
                self.timeoutObj && clearTimeout(self.timeoutObj);
                self.serverTimeoutObj && clearTimeout(self.serverTimeoutObj);
                self.timeoutObj = setTimeout(function () {
                    //这里发送一个心跳，后端收到后，返回一个心跳消息，
                    if (self.websocket.readyState == 1) {
                        //如果连接正常
                        self.websocket.send("heartCheck");
                    } else {
                        //否则重连
                        self.reconnect();
                    }
                    self.serverTimeoutObj = setTimeout(function () {
                        //超时关闭
                        self.websocket.close();
                    }, self.timeout);
                }, self.timeout);
            },
            // 连接发生错误的回调方法
            websocketOnerror() {
                this.$message.error("WebSocket连接发生错误");
                //重连
                this.reconnect();
            },
            // 连接成功建立的回调方法
            websocketOnopen() {
                this.websocketSend();
                console.log("连接成功建立的回调方法");
                //开启心跳
                this.start();
            },
            websocketSend() {
                //数据发送
                let sendData = {
                    order_no: window.localStorage.getItem("order_no"),
                    exam_uuid: window.localStorage.getItem("exam_uuid"),
                    token: this.token,
                };
                this.websocket.send(JSON.stringify(sendData));
            },
            // 接收到消息的回调方法
            websocketOnmessage(event) {
                console.log(event, "event");
                if (event.data !== "Opened") {
                    //const data = JSON.parse(event.data);
                    console.log("接收到消息的回调方法", event.data);
                    if (event.data == 1) {
                        this.lockReconnect = false;

                        this.closeWebSocket();
                        this.dialogVisible = false;
                        this.$emit("closeCFSUDialog", this.dialogVisible, true);

                        this.$router.replace({name: "applysuccess"});
                    } else {
                        //收到服务器信息，心跳重置
                        this.reset();
                    }
                }
            },
            // 连接关闭的回调方法
            websocketOnclose() {
                console.log("连接关闭的回调方法");
                //重连
                this.reconnect();
            },
            // 监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常
            websocketOnbeforeunload() {
                this.closeWebSocket();
                // console.log('监听窗口关闭事件，当窗口关闭时，主动去关闭websocket连接，防止连接还没断开就关闭窗口，server端会抛异常');
            },
            // 关闭WebSocket连接
            closeWebSocket() {
                this.websocket && this.websocket.close();
            },
        },
        destroyed() {
            clearInterval(this.timer);
        },
        components: {
            breadCrumb,
            CancelReserve,
            ConfirmSignUp,
            AgreeBox,
            MessageBox
        },
    };
</script>

<style scoped lang="scss">
    // 面包屑样式
    @import "a/scss/beradcrumb";
    // 表格样式
    @import "a/scss/table";

    #container {
        width: 100%;
        min-height: 80%;
        margin: 0 auto;
        overflow: auto;

        .el-main {
            max-width: 1300px;
            margin: 6px auto;
            padding: 0 50px;
            margin-bottom: 0;

            .content {
                background-color: #ffffff;
                padding: 40px 60px 0 60px;
                // height: 1080px;

                .h_title {
                    font-size: 24px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                }

                .h_info {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin: 22px 0;

                    span {
                        font-size: 30px;
                        color: #ee7602;
                        font-family: PingFangSC-Regular;
                    }
                }

                .info_txt {
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    margin: 24px 0;
                }

                .el-table {
                    margin-top: 20px;

                    &::before {
                        height: 0;
                    }
                }

                // 价格
                .price {
                    text-align: right;
                    font-size: 14px;
                    font-family: PingFang SC;
                    font-weight: 400;
                    color: #333333;
                    // margin: 158px 0 60px 0;
                    // margin: 58px 0 30px 0;
                    padding: 58px 0 30px 0;

                    span {
                        font-size: 24px;
                        font-family: PingFang SC;
                        font-weight: 400;
                        color: #563279;
                    }
                }

                // 底部按钮
                .foot-btn {
                    height: 88px;
                    padding: 20px 0;
                    border-top: 1px solid #e0e0e0;
                    text-align: right;

                    .el-button {
                        width: 120px;
                        height: 36px;
                        border: 0;
                        background-color: #cccccc;
                        margin: 0 0 0 20px;
                        border-radius: 4px;
                        padding: 0;
                        color: #ffffff;
                    }

                    .btn_1 {
                        background-color: #cccccc;
                    }

                    .btn_2 {
                        background-color: #563279;
                    }
                }
            }
        }
    }
</style>
